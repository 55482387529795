export const environment = {
  production: true,
  featureFlags: {
    auth: true,
  },
  cms: {
    api: 'https://adswp.azurewebsites.net/aveva-design-system/graphql/',
    imgRoot: 'https://adswp-a556d4e1987fefaa-endpoint.azureedge.net/blobadswpc786d65151/',
    glossaryRoot: '/build/resources/glossary/terms',
    menuRoot: 'dGVybToy',
    href: 'https://adswp.azurewebsites.net/aveva-design-system',
  },
  server: 'https://design.aveva.com',
  base: 'build',
  auth: {
    domain: 'signin.connect.aveva.com',
    clientID: 'j0bmTkpkzT9Hz5mSaoJ2yBRxxBGcLaYG',
    returnTo: 'https://design.aveva.com',
    callback: '/callback'
  },
  appInsights: {
    instrumentkey: '87d9ed32-71d0-4e7d-82a1-bd3b4d60b7e2',
    report: true,
  },
  iconServer: 'https://icons.aveva.design/graphql',
  cdn: 'https://cdn.aveva.design/',
  cdncms: 'https://cdn.aveva.design/adscms/',
  appicon: 'https://adscdn.blob.core.windows.net/icons/applications/',
  cdnversions: 'https://cdn.aveva.design/adscms/versions/versions.json',
  version: 'production',
  status: 'https://cdn.aveva.design/adscms/production/status.json',
};
